import { combineReducers } from 'redux';

function selectedCategory(state = null, action) {
  switch (action.type) {
    case 'SELECT_CATEGORY':
      return action.category;
    default:
      return state;
  }
}

function isDrawerOpen(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      return !state;
    default:
      return state;
  }
}

function searcherValue(state = '', action) {
  switch (action.type) {
    case 'SEARCH_VALUE':
      return action.value;
    default:
      return state;
  }
}

function fadeOnceRenderCount(state = 0, action) {
  switch (action.type) {
    case 'INC_FADE_ONCE_RENDER_COUNT':
      return state + 1;
    default:
      return state;
  }
}

export default combineReducers({
  selectedCategory,
  searcherValue,
  fadeOnceRenderCount,
  isDrawerOpen,
});
