import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { toURL, descToExcerpt } from '../utils/format';
import { mediaQueries } from '../utils/display';
import dotsSVG from '../assets/images/dots.svg';
import RlcDisplay from './RlcDisplay';

const LinkSwitch = ({ to, children }) => {
  if (to.includes('://'))
    return (
      <a target="_blank" rel="noreferrer noopener" href={to}>
        {children}
      </a>
    );
  return <Link to={to}>{children}</Link>;
};

const styles = (theme) => ({
  dappCard: {
    backgroundColor: '#f5f5f5',
    backgroundImage: `url(${dotsSVG})`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    '& a': {
      color: '#010101',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textDecoration: 'none',
    },
    '&:not($dappCardCustom):hover': {
      backgroundColor: 'rgba(1, 1, 1, 0.85)',
      transitionDuration: '300ms',
      transitionTimingFunction: 'ease-in-out',
    },
    '&:not($dappCardCustom):hover $logo': {
      display: 'none',
    },
    '&:not($dappCardCustom):hover $excerpt': {
      display: 'block',
    },
    '&:not($dappCardCustom):hover $author': {
      display: 'none',
    },
    '&:not($dappCardCustom):hover $viewDappButton': {
      display: 'flex',
    },
    '&:not($dappCardCustom):hover $name': {
      display: 'none',
    },
    '&:not($dappCardCustom):hover $price': {
      display: 'none',
    },
    '&:not($dappCardCustom):hover viewDappCustomButton': {
      display: 'flex',
    },
  },
  [mediaQueries.mobile.down]: {
    dappCard: {
      height: '100%',
      width: '90%',
    },
    excerpt: {
      color: 'black',
      display: 'block',
      fontSize: '1.1em',
      overflow: 'hidden',
      textAlign: 'justify',
      textOverflow: 'ellipsis',
    },
    viewDappButton: {
      borderRadius: '20px',
      fontSize: '1.2em',
      height: '38px',
      width: '50%',
    },
  },
  blackCard: {
    backgroundColor: '#010101',
    '& a': {
      color: '#ffff',
    },
  },
  logo: {
    backgroundColor: '#ffff',
    borderRadius: '10px',
    boxShadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.5)',
    height: '60px',
    margin: '20px',
    width: '60px',
  },
  excerpt: {
    color: 'white',
    display: 'none',
    margin: '8%',
    overflow: 'hidden',
    textAlign: 'justify',
    textOverflow: 'ellipsis',
  },
  dappCardCustom: {},
  name: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    [mediaQueries.mobile.down]: {
      fontSize: '1.5em',
    },
  },
  author: {},
  viewDappButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '1.1rem',
    color: theme.palette.primary.contrastText,
    display: 'none',
    fontSize: '0.8em',
    height: '28px',
    justifyContent: 'center',
    margin: 'auto',
    width: '100px',
  },
  viewDappCustomButton: {
    display: 'flex',
  },
  price: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '20px',
  },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
});

const DappCard = ({ classes, dapp, chainId }) => {
  if (dapp.type === 'custom')
    return (
      <div
        className={classNames(
          classes.dappCard,
          classes.dappCardCustom,
          dapp.theme && classes[dapp.theme],
        )}
        style={dapp.style || {}}
      >
        <LinkSwitch to={dapp.social.website}>
          <div>
            <img src={dapp.logo} alt={dapp.name} className={classes.logo} />
          </div>
          <div className={classes.name}>{dapp.name}</div>
          <div className={classes.author}>{dapp.author}</div>
          <div className={classes.spacer} />
          <div className={classes.excerpt}>{''}</div>
          <div className={classes.spacer} />
          {dapp.button !== false && (
            <div
              className={classNames(
                classes.viewDappButton,
                classes.viewDappCustomButton,
              )}
            >
              {dapp.buttonText}
            </div>
          )}
          <div className={classes.spacer} />
          <div className={classes.price}>{dapp.price}</div>
        </LinkSwitch>
      </div>
    );
  if (dapp.type === 'partner')
    return (
      <div className={classes.dappCard}>
        <LinkSwitch to={`/dapp/${toURL(dapp.org)}/${toURL(dapp.name)}`}>
          <div>
            <img src={dapp.logo} alt={dapp.name} className={classes.logo} />
          </div>
          <div className={classes.name}>{dapp.name}</div>
          <div className={classes.author}>{'by '.concat(dapp.org)}</div>
          <div className={classes.spacer} />
          <div className={classes.excerpt}>
            {descToExcerpt(dapp.description)}
          </div>
          <div className={classes.spacer} />
          <div className={classes.viewDappButton}>VIEW PROJECT</div>
          <div className={classes.spacer} />
          <div className={classes.price}>Partner</div>
        </LinkSwitch>
      </div>
    );
  return (
    <div className={classes.dappCard}>
      <LinkSwitch to={`/dapp/${toURL(dapp.org)}/${toURL(dapp.name)}`}>
        <div>
          <img src={dapp.logo} alt={dapp.name} className={classes.logo} />
        </div>
        <div className={classes.name}>{dapp.name}</div>
        <div className={classes.author}>{'by '.concat(dapp.author)}</div>
        <div className={classes.spacer} />
        <div className={classes.excerpt}>{descToExcerpt(dapp.description)}</div>
        <div className={classes.spacer} />
        <div className={classes.viewDappButton}>VIEW PROJECT</div>
        <div className={classes.spacer} />
        {chainId && dapp.prices && dapp.prices[chainId] !== undefined && (
          <div className={classes.price}>
            {dapp.prices[chainId] === 0 ? (
              'Free'
            ) : (
              <RlcDisplay amount={dapp.prices[chainId]} />
            )}
          </div>
        )}
      </LinkSwitch>
    </div>
  );
};

export default withStyles(styles)(DappCard);
