import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { set } from '../store/actions/app';
import { getDappsAsync } from '../store/actions/data';
import { mediaQueries } from '../utils/display';
import navBarLogo from '../assets/images/iexec-logo.svg';
import Login from './Login';
import Button from './Button';

const styles = (theme) => ({
  root: {
    'grid-area': 'header',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  leftBox: {
    display: 'flex',
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },
  logo: {
    height: 'auto',
    width: '6em',
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
  rightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bars: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: theme.palette.text.primary,
    padding: 20,
  },
  loginText: {
    fontSize: '1.3em',
  },
  submit: {
    color: theme.palette.primary.contrastText,
    marginLeft: 10,
    fontWeight: 'normal',
    [mediaQueries.medium.down]: {
      padding: '0.6em',
    },
    '& span': {
      [mediaQueries.medium.down]: {
        display: 'none',
      },
    },
  },
  uploadIcon: {
    margin: '0 5px',
  },
});

const NavBar = (props) => {
  const { getDapps, location, setSearch, setCat } = props;

  useEffect(() => {
    getDapps({});
    const { search, category } = queryString.parse(location.search);
    if (search) setSearch(search);
    if (category) setCat(category);
    // only once
  }, []);

  const { classes, toggleDrawer } = props;
  return (
    <div className={classes.root}>
      <div className={classes.leftBox}>
        <Link className={classes.logoBox} to={'/'}>
          <img className={classes.logo} src={navBarLogo} alt="iExec logo" />{' '}
          <div className={classes.title}> Dapp Store </div>
        </Link>
      </div>
      <div className={classes.rightBox}>
        <Login>
          <div className={classes.loginText}>Login</div>
        </Login>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={
            'https://github.com/iExecBlockchainComputing/iexec-dapps-registry'
          }
        >
          <Button className={classes.submit}>
            <div>
              <span>Submit your dapp</span>{' '}
              <FaUpload className={classes.uploadIcon} />
            </div>
          </Button>
        </a>
        <div
          className={classes.bars}
          onClick={() => toggleDrawer()}
          onKeyDown={() => toggleDrawer()}
        >
          <FaBars />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDapps: (query) => dispatch(getDappsAsync.request(query)),
  toggleDrawer: () => dispatch(set.toggleDrawer()),
  setSearch: (searchValue) => dispatch(set.searchValue(searchValue)),
  setCat: (cat) => dispatch(set.selectCategory({ value: cat, label: cat })),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(NavBar));
