import { combineReducers } from 'redux';
import { DEFAULT_CHAIN } from '../../config';
import { SET_CURRENT_CHAIN_ID } from '../types/chain';

function chainId(state = DEFAULT_CHAIN, action) {
  switch (action.type) {
    case SET_CURRENT_CHAIN_ID:
      return action.chainId;
    case 'RESET_CHAIN':
      return DEFAULT_CHAIN;
    default:
      return state;
  }
}

export default combineReducers({
  chainId,
});
