import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { loadState, saveState } from './utils/localStorage';
import explorerApp from './store/reducers/reducers';
import sagaWatchers from './store/sagas/sagas';
import './index.css';
import App from './components/App';

window.onbeforeunload = () =>
  saveState({
    chain: {
      chainId: store.getState().chain.chainId,
    },
  });

const sagaMiddleware = createSagaMiddleware();
const history = createHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  explorerApp(history),
  loadState(),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
  ),
);
sagaMiddleware.run(sagaWatchers);

store.subscribe(() => {});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
