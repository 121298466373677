import React from 'react';
// import Debug from 'debug'
import RSelect from 'react-select';
import { withTheme } from '@material-ui/core/styles';

// const debug = Debug('Select')
const customStyles = (theme) => ({
  option: (base, { isDisabled, isFocused }) => ({
    ...base,
    backgroundColor: isFocused ? '#f5f7fa' : null,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (base, state) => ({
    ...base,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 100,
    minHeight: 30,
    height: 30,
    minWidth: '8rem',
    backgroundColor: 'transparent',
    boxShadow: '0 0 1px #f4f4f4',
    ':hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 0 1px #f4f4f4',
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '2px 6px',
    justifyContent: 'center',
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 30,
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    visibility: 'hidden',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 4,
    color: state.isDisabled
      ? theme.palette.secondary.light
      : theme.palette.secondary.main,
    ':hover': {
      color: state.isDisabled
        ? theme.palette.secondary.light
        : theme.palette.secondary.main,
    },
  }),
});

const Select = ({ theme, options, value, onChange, label }) => (
  <RSelect
    onChange={onChange}
    openMenuOnFocus={true}
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    name="form-field-name"
    className="Select"
    classNamePrefix="select"
    value={value}
    styles={customStyles(theme)}
    options={options.map((name) => ({
      value: name,
      label: name,
      clearableValue: false,
    }))}
    isClearable={true}
  />
);

export default withTheme(Select);
