import React from 'react';
import { Route } from 'react-router-dom';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import { WalletManagerProvider } from '@iexec/react-wallet-manager';
import NavBar from './NavBar';
import DappDetails from './DappDetails';
import Footer from './Footer';
import Notifier from './Notifier';
import Drawer from './Drawer';
import DappsGrid from './DappsGrid';
import ControlBar from './ControlBar';
import 'hint.css';
import { INFURA_PROJECT_ID } from '../config';
import { multiWeb3 } from '../services/web3';
import Web3ProviderModal from '../components/Web3ProviderModal';
import AccountManagerModal from '../components/AccountManagerModal';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      light: '#D4DCEA',
      main: '#A2B5D2',
      dark: '#4F5C73',
      contrastText: '#647799',
    },
    background: {
      default: '#FFFFFF',
      paper: '#f5f7fa',
      red: '#EF5353',
      green: '#11B15E',
      grey: '#6C6C6C',
      main: (opacity = '0.3') => `rgba(250,233,0,${opacity})`,
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
      disabled: '#505050',
      link: '#3498db',
      focusLink: '#1d6fa5',
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 0,
      },
      scrollButtons: {
        color: '#FAE900',
        flex: '0 0 24px',
      },
    },
    MuiTab: {
      root: {
        color: '#000000',
        minWidth: '72px !important',
        textTransform: 'unset',
        fontSize: '1em',
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#383838',
        fontSize: '1em',
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flex: '0 0 auto',
        margin: '0.7em 0px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
    },
    MuiInput: {
      root: {
        color: '#000000',
        margin: 0,
        borderRadius: '1em',
        border: '1px solid #505050',
        minWidth: 0,
        minHeight: 0,
        flex: 2,
      },
      input: {
        padding: '0.2em 0.7em',
      },
      multiline: {
        '& div': {
          margin: '0.5em',
        },
      },
      inputMultiline: {
        padding: 0,
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      disableAnimation: true,
    },
  },
});

const styles = (theme) => ({
  // scrollbars
  '@global': {
    // chromium based
    '*::-webkit-scrollbar': {
      height: '10px',
      width: '10px',
    },
    '*::-webkit-scrollbar-track-piece': {
      backgroundColor: theme.palette.background.paper,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundClip: 'content-box',
      backgroundColor: theme.palette.primary.main,
      border: `3px solid ${theme.palette.background.paper}`,
      borderRadius: '5px',
    },
    // firefox
    '*': {
      scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
      scrollbarWidth: 'thin',
    },
  },
  app: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const App = withStyles(styles)((props) => {
  const { classes } = props;
  return (
    <div className={classes.app}>
      <Notifier />
      <Drawer />
      <Route component={NavBar} />
      <Route exact path={'/'} component={ControlBar} />
      <Route exact path={'/'} component={DappsGrid} />
      <Route path={'/dapp/:dappOrg/:dappName'} component={DappDetails} />
      <div className="Spacer" />
      <Footer />
    </div>
  );
});

const AppWrapper = () => (
  <MuiThemeProvider theme={theme}>
    <WalletManagerProvider multiWeb3={multiWeb3} infura={INFURA_PROJECT_ID}>
      <Web3ProviderModal />
      <AccountManagerModal />
      <App />
    </WalletManagerProvider>
  </MuiThemeProvider>
);

export default AppWrapper;
