import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FaMedium } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaExternalLinkAlt } from 'react-icons/fa';
import copy from 'copy-to-clipboard';
import get from 'lodash.get';
import qs from 'query-string';
import classNames from 'classnames';
import rlcIcon from '../assets/images/rlc-logo.png';
import * as notifierActions from '../store/actions/notifier';
import { getChainName, chains, isSupportedChain } from '../utils/chain';
import { toURL } from '../utils/format';
import { mediaQueries } from '../utils/display';
import { MARKETPLACE_BASE_URL } from '../config';
import RlcDisplay from './RlcDisplay';
import { useWallet } from '@iexec/react-wallet-manager';

const icons = {
  website: FaExternalLinkAlt,
  medium: FaMedium,
  twitter: FaTwitter,
  linkedin: FaLinkedin,
  github: FaGithub,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    margin: '20px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  spacer: {
    height: '10px',
  },
  leftBox: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '30px',
    minWidth: '150px',
  },
  rightBox: {
    display: 'flex',
    flex: 4,
    flexDirection: 'column',
    marginBottom: '20px',
  },
  logo: {
    position: 'relative',
  },
  logoIMG: {
    borderRadius: '10px',
    height: '100px',
    width: '100px',
  },
  goBadge: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    bottom: '-5px',
    cursor: 'pointer',
    display: 'flex',
    height: '30px',
    justifyContent: 'center',
    width: '100px',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: '10px',
      fontWeight: 'bold',
      transform: 'translateY(2px)',
    },
  },
  price: {
    fontWeight: 800,
    marginTop: '20px',
  },
  priceIMG: {
    height: '15px',
    marginBottom: '0.3em',
    marginLeft: '0.5em',
    position: 'relative',
    verticalAlign: 'middle',
    width: '15px',
  },
  license: {
    fontWeight: 800,
    marginTop: '20px',
  },
  created: {
    fontWeight: 800,
    marginTop: '20px',
  },
  nameBox: {
    fontWeight: 'bold',
  },
  nameBoxSpan: {
    fontSize: '18px',
    marginRight: '10px',
  },
  author: {
    fontWeight: 800,
  },
  social: {
    fontSize: '1.5em',
    marginTop: '20px',
  },
  socialIcon: {
    margin: '5px',
  },
  website: {
    transform: ' translateY(2px)',
  },
  linkedin: {
    color: '#1a80b9',
  },
  twitter: {
    color: '#55acee',
  },
  chain: {
    margin: '5px',
  },
  clipboard: {
    cursor: 'copy',
  },
  description: {
    marginTop: '20px',
    maxWidth: '650px',
    textAlign: 'justify',
  },
  [mediaQueries.mobile.down]: {
    root: {
      flexDirection: 'column',
    },
    leftBox: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    rightBox: {
      alignItems: 'center',
    },
    metatags: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  },
});

const DappDetails = ({ match, notify, isLoading, dapp, classes, chainId }) => {
  const { connected, provider } = useWallet();
  if (dapp) {
    const isDapp = dapp.type === 'dapp';
    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <div>
            <div className={classes.logo}>
              <img
                src={dapp.logo}
                alt={dapp.name}
                className={classes.logoIMG}
              />
            </div>
            {connected ? (
              dapp.addresses && chainId in dapp.addresses ? (
                <a
                  className={classNames(classes.goBadge, 'hint--bottom-right')}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${MARKETPLACE_BASE_URL}?${qs.stringify({
                    chainId: chainId || undefined,
                    provider: (connected && provider) || undefined,
                    dapp: get(dapp, `addresses[${chainId}]`, undefined),
                    iexec_args: get(
                      dapp,
                      `buyConf.params.iexec_args`,
                      undefined,
                    ),
                    trust: get(dapp, `buyConf.trust`, undefined),
                    tag: get(dapp, `buyConf.tag`, undefined),
                  })}`}
                  aria-label="Click to submit a work"
                >
                  <p>Submit Work</p>
                </a>
              ) : (
                <div
                  className={classNames(classes.goBadge, 'hint--bottom-right')}
                  aria-label={`Not deployed on current chain ${getChainName(
                    chainId,
                  )}`}
                >
                  <p>Submit Work</p>
                </div>
              )
            ) : (
              <div
                className={classNames(classes.goBadge, 'hint--bottom-right')}
                aria-label="Login to submit a work"
              >
                <p>Submit Work</p>
              </div>
            )}
          </div>
          <div className={classes.metatags}>
            {isDapp && connected ? (
              dapp.prices && chainId in dapp.prices ? (
                <div className={classes.price}>
                  {dapp.prices[chainId] === 0 && 'Free '}
                  {dapp.prices[chainId] !== 0 && (
                    <RlcDisplay amount={dapp.prices[chainId]} />
                  )}
                  {dapp.prices[chainId] !== 0 && (
                    <img
                      className={classes.priceIMG}
                      src={rlcIcon}
                      alt="RLC token"
                    />
                  )}
                </div>
              ) : (
                <div className={classes.price}>Currently unavailable</div>
              )
            ) : (
              <div className={classes.price}>Login to see price</div>
            )}
            <div className={classes.license}>License</div>
            <div>{dapp.license}</div>
            <div className={classes.created}>Created</div>
            <div>{new Date(dapp.created).toLocaleDateString()}</div>
          </div>
        </div>

        <div className={classes.rightBox}>
          <div className={classes.nameBox}>
            <span className={classes.nameBoxSpan}>{dapp.name}</span>
          </div>
          <div className={classes.author}>by {dapp.author}</div>

          {dapp.social && (
            <div className={classes.social}>
              {Object.keys(dapp.social).map((e) => {
                const Icon = icons[e];
                return (
                  <a
                    key={e}
                    target="_blank"
                    rel="noreferrer noopener"
                    href={dapp.social[e]}
                  >
                    <Icon
                      key={e}
                      className={classNames(
                        classes.socialIcon,
                        classes[e],
                        'Icon',
                      )}
                    />
                  </a>
                );
              })}
            </div>
          )}

          {dapp.addresses && (
            <div className={classes.social}>
              {['Addresses: '].concat(
                Object.keys(dapp.addresses).map((e) => {
                  if (!isSupportedChain(e)) return '';
                  return (
                    <div
                      className={classNames(
                        classes.chain,
                        classes.clipboard,
                        'hint--top',
                      )}
                      key={e}
                      aria-label="click to copy the dapp address"
                      onClick={() => {
                        copy(dapp.addresses[e]);
                        notify({
                          message: `address copied to clipboard`,
                        });
                      }}
                    >
                      #{chains[e] && chains[e].name}
                    </div>
                  );
                }),
              )}
            </div>
          )}
          <div className={classes.description}>{dapp.description}</div>
        </div>
      </div>
    );
  }
  if (isLoading['GET_DAPPS']) return <div className={classes.root} />;
  return (
    <div
      className={classes.root}
    >{`Sorry! "${match.params.dappOrg}/${match.params.dappName}" dapp not found!`}</div>
  );
};

const mapStateToProps = (state, { match }) => {
  const dapp = state.data.dapps.find(
    (e) =>
      match.params.dappOrg === toURL(e.org) &&
      match.params.dappName === toURL(e.name),
  );
  return {
    isLoading: state.isLoading,
    dapp,
    chainId: state.chain.chainId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  notify: (params) => dispatch(notifierActions.notify(params)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DappDetails),
);
