import {
  CHANGE_CHAIN,
  PROVIDER_ACCOUNT_CHANGED,
  PROVIDER_NETWORK_CHANGED,
  RESET_CHAIN,
  SET_CURRENT_CHAIN_ID,
} from '../types/chain';

export const changeChain = (chainName) => ({ type: CHANGE_CHAIN, chainName });

export const setCurrentChain = (chainId) => ({
  type: SET_CURRENT_CHAIN_ID,
  chainId,
});

export const resetChain = () => ({
  type: RESET_CHAIN,
});

export const providerAccountChanged = () => ({
  type: PROVIDER_ACCOUNT_CHANGED,
});

export const providerNetworkChanged = () => ({
  type: PROVIDER_NETWORK_CHANGED,
});
