export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const descToExcerpt = (desc) => {
  return desc.slice(0, 147).concat('...');
};

export const toShortHash = (string) =>
  `${''.concat(string.substring(0, 8), '...', string.slice(-8))}`;

export const toURL = (str) => str.toLowerCase().split(' ').join('_');
