import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DappCard from '../components/DappCard';
import FadeOnce from './FadeOnce';
import { mediaQueries } from '../utils/display';

const debug = Debug('DappsGrid');

const styles = (theme) => ({
  dappsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: '350px',
    gridGap: '2rem',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '10px',
    [mediaQueries.mobile.up]: {
      gridTemplateColumns: 'repeat(auto-fill, 190px)',
      gridAutoRows: '220px',
    },
  },
});

const DappsGrid = ({
  classes,
  dapps,
  searcherValue,
  selectedCategory,
  chainId,
}) => {
  debug('dapps', dapps);

  const matchCatDapps = dapps.filter((e) => {
    if (!selectedCategory) return true;
    return e.categories && e.categories.includes(selectedCategory.value);
  });
  const matchCatSearchDapps = matchCatDapps.filter((e) =>
    e.name.toLowerCase().includes(searcherValue.toLowerCase()),
  );
  debug('matchCatSearchDapps', matchCatSearchDapps);
  return (
    <FadeOnce timeout={1000}>
      <div className={classes.dappsGrid}>
        {matchCatSearchDapps.map((e, i) => (
          <DappCard dapp={e} key={i} chainId={chainId} />
        ))}
      </div>
    </FadeOnce>
  );
};

const mapStateToProps = (state) => ({
  dapps: state.data.dapps.sort((a, b) => {
    if (a.rank > b.rank) return -1;
    if (a.rank < b.rank) return 1;
    if (a.created > b.created) return -1;
    if (a.created < b.created) return 1;
    return 0;
  }),
  chainId: state.chain.chainId,
  searcherValue: state.app.searcherValue,
  selectedCategory: state.app.selectedCategory,
});

export default connect(mapStateToProps)(withStyles(styles)(DappsGrid));
