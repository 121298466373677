import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '1.5em',
    cursor: 'pointer',
    margin: '10px 0px 10px 0px',
    padding: '0.6em 1.7em',
    fontWeight: 'bold',
    justifyContent: 'center',
  },
  disabled: {
    cursor: 'not-allowed',
  },
});

const Button = ({
  classes,
  className,
  children,
  disabled = false,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      classes.button,
      disabled && classes.disabled,
      className,
    )}
  >
    {children}
  </div>
);

export default withStyles(styles)(Button);
