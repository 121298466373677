import React from 'react';
import { MdSearch } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
    borderRadius: 100,
    display: 'flex',
    height: '30px',
    margin: '0 10px',
  },
  iconBox: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '5px',
  },
  input: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '15px',
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexGrow: 1,
    marginLeft: '5px',
    '&::placeholder': {
      color: theme.palette.secondary.contrastText,
      fontSize: '12px',
      textAlign: 'center',
    },
  },
});

let SearchForm = ({ classes, handleSubmit, value, onChange }) => (
  <div className={classes.root}>
    <div className={classes.iconBox}>
      <MdSearch size={20} />
    </div>
    <Input
      className={classes.input}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Search by dapp name"
    />
  </div>
);

export default withStyles(styles)(SearchForm);
