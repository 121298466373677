import Debug from 'debug';
import { all, put, takeLatest } from 'redux-saga/effects';
import { jsonApi } from '../../services/api';
import * as dataActions from '../actions/data';
import { getApiUrl } from '../../utils/chain';
import { PROVIDER_ACCOUNT_CHANGED, SET_CURRENT_CHAIN_ID } from '../types/chain';

const debug = Debug('sagas:data');

export function* refreshData() {
  debug('refreshData()');
  yield put(dataActions.getDappsAsync.request());
}

export function* getDapps({ query }) {
  try {
    debug('getDapps() query', query);
    const api = getApiUrl();
    const data = yield jsonApi.post({ api, endpoint: '/dapps' });
    yield put(dataActions.getDappsAsync.success(data.dapps));
  } catch (error) {
    yield put(dataActions.getDappsAsync.failure(error.message));
  }
}

// SAGAS WATCHERS
export function* watchData() {
  yield takeLatest(SET_CURRENT_CHAIN_ID, refreshData);
  yield takeLatest(PROVIDER_ACCOUNT_CHANGED, refreshData);
  yield takeLatest('GET_DAPPS_REQUEST', getDapps);
}

export default function* dataSaga() {
  yield all([watchData()]);
}
