const createMediaQuery = (breakpoint) => ({
  up: `@media (min-width: ${breakpoint}px)`,
  down: `@media (max-width: ${breakpoint}px)`,
});

export const breakpoints = {
  mobile: 540,
  small: 600,
  medium: 750,
  big: 1400,
};

export const mediaQueries = {
  mobile: createMediaQuery(breakpoints.mobile),
  small: createMediaQuery(breakpoints.small),
  medium: createMediaQuery(breakpoints.medium),
  big: createMediaQuery(breakpoints.big),
};

export const mobileDisplay = window.innerWidth <= breakpoints.mobile;

export const mediumDisplay = window.innerWidth <= breakpoints.medium;
