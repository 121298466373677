import Debug from 'debug';
import { select, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as chainActions from '../actions/chain';
import * as notifierActions from '../actions/notifier';
import { isSupportedChain, chains } from '../../utils/chain';
import { capitalizeFirstLetter } from '../../utils/format';
import { DEFAULT_CHAIN } from '../../config';
import { multiWeb3 } from '../../services/web3';
import { CHANGE_CHAIN, PROVIDER_ACCOUNT_CHANGED } from '../types/chain';

const debug = Debug('sagas:chain');

export function* refreshChain(action) {
  debug('refreshChain()', action);
  const { connected, chainId: web3ChainId, provider } = multiWeb3.getStatus();
  const initialChainId = yield select((state) => state.chain.chainId);
  const actionChainId =
    action &&
    (action.chainId ||
      (chains[action.chainName] && chains[action.chainName].id));
  let chainId = initialChainId;

  if (connected) {
    debug(`web3provider connected to ${web3ChainId}`);
    if (isSupportedChain(web3ChainId)) {
      chainId = web3ChainId;
    } else {
      debug(
        `web3provider chain ${web3ChainId} is not supported, fallback to default ${DEFAULT_CHAIN}`,
      );
      const providerDisplay = capitalizeFirstLetter(provider);
      yield put(
        notifierActions.notify({
          level: 'warning',
          message: `${providerDisplay} current chain ${web3ChainId} is not supported.
          Use ${providerDisplay} to switch chain`,
        }),
      );
      chainId = DEFAULT_CHAIN;
    }
  } else if (actionChainId) {
    debug('chain changed by user');
    if (isSupportedChain(actionChainId)) {
      chainId = actionChainId || initialChainId;
    } else {
      debug(`user chain ${actionChainId} is not supported`);
    }
  }

  debug(`chainId: ${chainId}`);
  if (initialChainId !== chainId) {
    yield put(chainActions.setCurrentChain(chainId));
  } else {
    debug("chain didn't changed");
  }
}

export function* watchChain() {
  // on load trigger
  yield takeEvery('STARTUP', refreshChain);
  // user action trigger
  yield takeLatest(CHANGE_CHAIN, refreshChain);
  // web3provider trigger
  yield takeLatest(PROVIDER_ACCOUNT_CHANGED, refreshChain);
}

export default function* chainSaga() {
  yield all([watchChain()]);
}
