export const API_BASE_URL = `https://v7.gateway.iex.ec`;

export const EXPLORER_BASE_URL = `https://explorer.iex.ec`;

export const MARKETPLACE_BASE_URL = `https://market.iex.ec`;

export const DAPPSTORE_BASE_URL = `https://dapps.iex.ec`;

export const WORKERPOOLS_BASE_URL = `https://pools.iex.ec`;

export const BLOCKCHAIN_EXPLORER_BASE_URL = {
  bellecour: 'https://blockscout-bellecour.iex.ec',
};

export const DEFAULT_PROVIDER = {
  bellecour: 'https://bellecour.iex.ec',
};

export const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;

// comment here to remove chain
// default first
export const SUPPORTED_CHAIN_KEYS = ['bellecour'];

export const DEFAULT_CHAIN = 134;

export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
